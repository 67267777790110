<template>
  <div>
    <div>
      <vue-element-loading
        :active="appLoading"
        spinner="bar-fade-scale"
        color="#1976d2"
        size="128"
        is-full-screen
      />
    </div>
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-dialog
      v-model="sizeChartDialog"
      :width="
        $vuetify.breakpoint.name == 'xs'
          ? '100vw'
          : $vuetify.breakpoint.name == 'sm'
          ? '50vw'
          : $vuetify.breakpoint.name == 'md'
          ? '40vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '40vw'
          : '25vw'
      "
    >
      <v-card tile color="#FFFFFF">
        <v-layout wrap justify-center pa-2>
          <v-flex
            v-if="product.subcategory && product.subcategory.sizeChart != null"
            xs12
          >
            <v-img :src="baseURL + product.subcategory.sizeChart" cover>
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="#FF4343"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <ServerError v-if="ServerError" />

    <v-layout v-if="product" wrap justify-center fill-height>
      <v-flex xs12 sm12 md5 lg5 xl4 pl-2>
        <v-layout wrap>
          <v-flex xs12>
            <v-card
              outlined
              tile
              elevation="0"
              :height="
                $vuetify.breakpoint.name == 'xs' ||
                $vuetify.breakpoint.name == 'sm' ||
                $vuetify.breakpoint.name == 'md'
                  ? '100vh'
                  : '105vh'
              "
            >
              <v-layout wrp justify-center>
                <v-flex xs12 text-left py-4>
                  <ImageZoomer />
                </v-flex>
              </v-layout>
              <v-layout wrap justify-center fill-height>
                <v-flex xs12 sm12 md8 text-center align-self-center>
                  <span class="hidden-md-and-up">
                    <v-img
                      v-if="!curImage"
                      :src="baseURL + photos[0]"
                      max-height="60vh"
                      contain
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="#FF2323"
                          ></v-progress-circular>
                        </v-row>
                        <v-layout justify-start v-if="product.productTag">
                          <v-flex xs8 align-self-start text-left>
                            <v-card
                              class="clip"
                              color="#FF0000"
                              height="10vh"
                              width="100%"
                            >
                              <v-layout justify-start style="height: 6vh">
                                <v-flex
                                  xs4
                                  align-self-center
                                  text-center
                                  style="transform: rotate(-40deg)"
                                >
                                  <v-layout wrap justify-center>
                                    <span
                                      class="text-uppercase pr-1"
                                      style="
                                        font-weight: bold;
                                        color: #fff;
                                        font-size: 9px;
                                      "
                                      >Best
                                    </span>
                                  </v-layout>
                                  <v-layout wrap justify-center>
                                    <span
                                      class="text-uppercase pr-1"
                                      style="
                                        font-weight: bold;
                                        color: #fff;
                                        font-size: 9px;
                                      "
                                      >Seller</span
                                    >
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-flex>
                        </v-layout>
                      </template>
                    </v-img>
                    <v-img
                      v-if="curImage"
                      :src="mediaURL + curImage"
                      max-height="60vh"
                      contain
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="#FF2323"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </span>
                  <span class="hidden-sm-and-down">
                    <inner-image-zoom
                      v-if="!curImage"
                      :src="mediaURL + photos[0]"
                      :zoom-src="mediaURL + photos[0]"
                      zoomType="hover"
                      :hideHint="true"
                      :zoomPreload="true"
                    >
                    </inner-image-zoom>
                    <inner-image-zoom
                      v-if="curImage"
                      :src="mediaURL + curImage"
                      :zoom-src="mediaURL + curImage"
                      zoomType="hover"
                      :hideHint="true"
                      :zoomPreload="true"
                    >
                    </inner-image-zoom>

                    <!-- <image-magnifier
                      v-if="!curImage"
                      :src="baseURL + photos[0]"
                      :zoom-src="baseURL + photos[0]"
                      width="100%"
                      height="auto"
                      zoom-width="300"
                      zoom-height="400"
                    >
                    </image-magnifier>
                    <image-magnifier
                      v-if="curImage"
                      :src="baseURL + curImage"
                      :zoom-src="baseURL + curImage"
                      width="100%"
                      height="auto"
                      zoom-width="300"
                      zoom-height="400"
                    ></image-magnifier> -->
                  </span>
                </v-flex>
                <v-flex xs12 sm8 md12 pa-3 pt-0 pt-sm-8 pt-md-6>
                  <v-layout wrap>
                    <v-flex xs10 align-self-center text-center>
                      <v-card outlined elevation="0" tile>
                        <v-layout wrap py-1>
                          <v-flex>
                            <span
                              :style="{
                                'font-size':
                                  $vuetify.breakpoint.name == 'xs'
                                    ? '10px'
                                    : $vuetify.breakpoint.name == 'sm'
                                    ? '13px'
                                    : $vuetify.breakpoint.name == 'md'
                                    ? '10px'
                                    : $vuetify.breakpoint.name == 'lg'
                                    ? '13px'
                                    : '13px',
                              }"
                              >{{ baseURL }}{{ imageUrl }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-flex xs2>
                      <v-card
                        style="cursor: pointer"
                        outlined
                        color="blue"
                        elevation="0"
                        tile
                      >
                        <v-layout wrap py-1>
                          <v-flex text-center>
                            <span
                              @click.stop.prevent="copyImageUrl"
                              style="color: #fff; text-decoration: none"
                              :style="{
                                'font-size':
                                  $vuetify.breakpoint.name == 'xs'
                                    ? '13px'
                                    : $vuetify.breakpoint.name == 'sm'
                                    ? '16px'
                                    : $vuetify.breakpoint.name == 'md'
                                    ? '13px'
                                    : $vuetify.breakpoint.name == 'lg'
                                    ? '16px'
                                    : '16px',
                              }"
                            >
                              copy
                            </span>
                            <input
                              type="hidden"
                              id="testing-code"
                              :value="baseURL + imageUrl"
                            />
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs11>
                  <v-card tile flat>
                    <v-layout wrap justify-center>
                      <v-flex xs2 v-for="(item, i) in photos" :key="i">
                        <v-card
                          @click="showImage(item), (imageUrl = item)"
                          flat
                          tile
                        >
                          <v-layout wrap justify-center>
                            <v-flex pa-2>
                              <v-img
                                :src="baseURL + item"
                                height="100px"
                                contain
                              >
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-progress-circular
                                      indeterminate
                                      color="#FF2323"
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 pa-3>
            <!-- <v-layout pt-2>
              <span style="font-size: 20px; font-weight: 600"
                >Product Details</span
              >
            </v-layout> -->
            <v-layout wrap justify-start pt-3>
              <v-flex xs6 sm6 md4 lg6 xl4>
                <span
                  style="
                    font-weight: bold;
                    font-size: 14px;
                    text-transform: none;
                  "
                >
                  STOCK QUANTITY
                </span>
                <span
                  class="pl-4"
                  style="font-size: 14px; font-weight: bold; color: #52d25b"
                >
                  {{ product.stockquantity }}
                </span>
              </v-flex>
              <v-flex xs6 sm6 md4 lg6 xl4 pl-2>
                <span
                  style="
                    font-weight: bold;
                    font-size: 14px;
                    text-transform: uppercase;
                  "
                >
                  Commission
                </span>
                <span
                  class="pl-4"
                  style="font-size: 14px; font-weight: bold; color: #52d25b"
                >
                  <span v-if="product.styloopcommision">
                    Rs.{{ product.styloopcommision.toFixed(2) }}</span
                  >
                  <span v-else> Nil</span>
                </span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-3>
              <v-flex xs6 sm6 md6 lg6 xl24pl-2>
                <span
                  style="
                    font-weight: bold;
                    font-size: 16px;
                    text-transform: none;
                  "
                >
                  Tax
                </span>
                <span
                  class="pl-4"
                  style="font-size: 14px; font-weight: bold; color: #52d25b"
                >
                  {{ product.tax }}
                </span>
              </v-flex>
              <v-flex xs6 sm6 md6 lg6 xl4 pl-2>
                <span
                  style="
                    font-weight: bold;
                    font-size: 16px;
                    text-transform: none;
                  "
                >
                  GST
                </span>
                <span
                  class="pl-4"
                  style="font-size: 14px; font-weight: bold; color: #52d25b"
                >
                  {{ product.gst }}
                </span>
              </v-flex>
            </v-layout>

            <v-layout pt-4 wrap justify-start>
              <!-- </v-flex> -->
            </v-layout>
            <v-layout wrap justify-start>
              <v-flex
                v-if="product.status == 'Pending'"
                xs12
                pb-4
                pb-sm-0
                tetx-center
                md5
                align-self-center
                pl-3
              >
                <v-dialog
                  v-model="dialogapprove"
                  max-width="600px"
                  :key="dialog"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      outlined
                      color="green"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span style="font-size: 11px"> Approve Product </span>
                    </v-btn>
                  </template>
                  <v-card>
                    <!-- <v-form v-model="addcat" ref="addcat"> -->
                    <v-card-title>
                      <span class="headline">Approve Product</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-layout wrap>
                          <v-flex xs12 sm6 md12>
                            <v-select
                              dense
                              multiple
                              required
                              v-model="product.styl"
                              class="mt-5"
                              label="Styl-Category *"
                              :items="stylCategoryArray"
                              item-text="name"
                              item-value="_id"
                            >
                            </v-select>
                          </v-flex>
                          <v-flex xs12 sm6 md6>
                            <v-text-field
                              v-model="product.comissionPercentage"
                              label="Commission Percentage"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 md6 pl-4>
                            <v-text-field
                              v-model="product.deliverycharge"
                              label="Delivery Charge"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogapprove = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="product.styl.length <= 0 ? true : false"
                        @click="approveProduct()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                    <!-- </v-form> -->
                  </v-card>
                </v-dialog>
              </v-flex>
              <v-flex xs6 sm4 md6 lg4>
                <template>
                  <v-dialog v-model="dialog" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small v-on="on" v-bind="attrs" outlined color="red"
                        ><span style="font-size: 11px"
                          >Remove Product</span
                        ></v-btn
                      >
                    </template>
                    <v-card>
                      <v-card-title
                        >Are you sure you want to remove this
                        Product?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="dialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="removeProduct()"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
              </v-flex>

              <v-flex xs6 sm4 md6 lg3 pl-4>
                <v-btn
                  class="px-2"
                  outlined
                  color="red"
                  small
                  @click="edit(product._id)"
                  ><span style="font-size: 11px">Edit Product </span>
                </v-btn>
              </v-flex>
              <v-flex
                xs12
                sm4
                md6
                pt-4
                pt-sm-0
                pt-md-4
                v-if="product.status == 'Active'"
              >
                <v-layout wrap justify-start>
                  <v-flex v-if="product.isintopcollection" xs6 sm6 md6 lg4 xl2>
                    <v-flex>
                      <v-dialog v-model="dialogdelete" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            outlined
                            v-on="on"
                            v-bind="attrs"
                            color="red"
                          >
                            <span style="font-size: 11px">
                              Remove From Top Collection
                            </span>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title
                            >Are you sure you want to delete this in top
                            collection?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="dialogdelete = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="deleteItem()"
                              >OK</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-flex>
                  </v-flex>
                  <v-flex v-else xs6 sm6 md6 lg4 xl2>
                    <v-dialog
                      v-model="dialogadd"
                      max-width="600px"
                      :key="dialog"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          outlined
                          color="green"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span style="font-size: 11px">
                            Add Top Collection
                          </span>
                        </v-btn>
                      </template>
                      <v-card>
                        <!-- <v-form v-model="addcat" ref="addcat"> -->
                        <v-card-title>
                          <span class="headline">Top Collection</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <!-- <v-col cols="12" sm="6" md="6">
                                <v-checkbox
                                  v-model="checkbox"
                                  label="Is Highlight"
                                ></v-checkbox>
                              </v-col> -->
                              <v-col cols="12" sm="6" md="6">
                                <v-select
                                  dense
                                  v-model="collectionid"
                                  class="mt-5 text-uppercase"
                                  label="Sub Category "
                                  :items="subcategoryArray"
                                  item-text="name"
                                  item-value="_id"
                                >
                                </v-select>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="dialogadd = false"
                          >
                            Close
                          </v-btn>
                          <v-btn color="blue darken-1" text @click="add()">
                            Save
                          </v-btn>
                        </v-card-actions>
                        <!-- </v-form> -->
                      </v-card>
                    </v-dialog>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm12 md7 lg7 xl8>
        <v-layout wrap justify-center>
          <v-flex xs11>
            <v-layout wrap justify-start>
              <v-flex xs12 text-left>
                <span
                  style="font-weight: bold; font-size: 22px; color: #000000"
                >
                  {{ product.productname }}
                </span>
              </v-flex>
              <v-flex xs12 text-left>
                <span style="font-size: 15px; color: #979797">
                  {{ product.caption }}
                </span>
              </v-flex>
              <v-flex xs12 text-left pt-4 v-if="product.offer">
                <v-btn small depressed color="#FF1313" tile>
                  <span style="font-size: 12px; color: #ffffff">
                    {{ product.offer }} %
                  </span>
                </v-btn>
              </v-flex>
              <v-flex xs12>
                <v-layout wrap justify-center>
                  <v-flex xs6>
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left>
                        <span
                          style="
                            font-size: 25px;
                            color: #000000;
                            font-weight: 600;
                          "
                        >
                          Rs. {{ product.price }}
                        </span>
                      </v-flex>
                      <v-flex xs12 text-left>
                        <span
                          class="text-decoration-line-through"
                          style="font-size: 16px; color: #ff0000"
                          v-if="product.savedamount > 0"
                        >
                          Rs. {{ product.orginalprice }}
                        </span>
                        <span
                          v-if="product.instock"
                          style="
                            font-size: 16px;
                            font-weight: bold;
                            color: #52d25b;
                          "
                        >
                          In Stock
                        </span>
                        <span
                          v-if="!product.instock"
                          style="
                            font-size: 14px;
                            font-weight: bold;
                            color: #ff4343;
                          "
                        >
                          Out of Stock
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6 text-left align-self-center>
                    <v-layout wrap justify-start align-content-center>
                      <v-flex xs2 text-right px-2 align-self-center>
                        <span
                          style="font-size: 14px; color: #8f8f8f"
                          class="caption"
                        >
                          {{ product.ratingcount }}
                        </span>
                      </v-flex>
                      <v-flex xs8 text-left px-2 align-self-center>
                        <v-rating
                          :value="parseFloat(product.rating)"
                          color="#FFD341"
                          background-color="#e0e0e0"
                          empty-icon="$ratingFull"
                          half-increments
                          readonly
                          hover
                          small
                        ></v-rating>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 text-left py-4>
                <v-layout wrap pb-3>
                  <span style="font-weight: 600; font-size: 14px; color: #000"
                    >SELECT SIZE</span
                  >
                  <span
                    v-if="
                      product.subcategory &&
                      product.subcategory.sizeChart != null
                    "
                    class="pl-3"
                    @click="sizeChartDialog = true"
                    style="
                      font-weight: 600;
                      font-size: 14px;
                      color: #ff1313;
                      cursor: pointer;
                    "
                    >SIZE CHART</span
                  >
                </v-layout>
                <span v-for="(item, i) in product.size" :key="i" class="px-1">
                  <v-btn
                    small
                    rounded
                    depressed
                    outlined
                    @click="changeSize(item)"
                    :ripple="false"
                    :color="
                      productSize.size == item.size ? '#FF0000' : '#919191'
                    "
                    class="sizebutton px-2"
                  >
                    {{ item.size }}
                  </v-btn>
                </span>
              </v-flex>
              <!-- <v-flex xs12 text-left v-if="productSize.stock">
                <span style="font-size: 12px; color: #ff0000">
                  {{ productSize.stock }} Left
                </span>
              </v-flex> -->
              <v-flex xs12 text-left pt-2 v-if="productSize.stock">
                <v-layout wrap justify-start>
                  <v-flex xs12 text-left>
                    <span style="font-size: 16px; color: #000000"> COLOR </span>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start pt-1>
                  <v-flex
                    xs2
                    sm2
                    md1
                    lg1
                    xl1
                    align-self-center
                    v-for="(item, i) in productSize.stock"
                    :key="i"
                    px-2
                  >
                    <v-card
                      outlined
                      rounded="50px"
                      class="colorselector"
                      width="40px"
                      @click="selectProduct(item)"
                      :color="
                        selectedProduct.color == item.color ? '#a9a9a9' : ''
                      "
                    >
                      <v-layout py-2 wrap align-content-center>
                        <v-flex xs12 text-center>
                          <v-avatar :color="item.color" size="15" />
                        </v-flex>
                        <v-flex xs12 style="padding-top: 2px" text-center>
                          <span style="font-size: 12px; color: #808080">{{
                            item.stock
                          }}</span>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 text-left pt-2>
                <v-divider></v-divider>
                <v-layout
                  wrap
                  justify-start
                  pt-2
                  v-if="product.materialused && product.materialused.length > 0"
                >
                  <!-- <v-flex xs12 text-left pt-1 v-if="product.description">
                    <span
                      style="font-weight: bold; font-size: 16px; color: #000000"
                    >
                      Description
                    </span>
                  </v-flex> -->
                  <v-flex xs12 text-left>
                    <span style="font-size: 15px; color: #979797">
                      {{ product.description }}
                    </span>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start>
                  <v-flex xs12>
                    <v-layout wrap justify-start pt-2>
                      <v-flex xs12 text-left>
                        <v-layout
                          v-if="categoryArray && categoryArray.length > 0"
                          wrap
                          justify-start
                        >
                          <v-flex xs12 text-left>
                            <span
                              style="
                                font-weight: bold;
                                font-size: 16px;
                                text-transform: none;
                              "
                            >
                              Category
                            </span>
                          </v-flex>
                          <v-flex xs12 text-left>
                            <v-chip
                              v-for="(item, i) in categoryArray"
                              :key="i"
                              class="pl-4 ma-2"
                              style="font-size: 14px"
                            >
                              {{ item.name }}
                            </v-chip>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 text-left>
                        <v-layout wrap justify-start v-if="subcat">
                          <v-flex xs12 text-left>
                            <span
                              style="
                                font-weight: bold;
                                font-size: 16px;
                                color: #000000;
                              "
                            >
                              Sub-Category
                            </span>
                          </v-flex>
                          <v-flex xs12 text-left>
                            <v-chip class="pl-4 ma-2" style="font-size: 14px">
                              {{ subcat.name }}
                            </v-chip>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout
                      v-if="styl && styl.length > 0"
                      wrap
                      justify-start
                      pt-2
                    >
                      <v-flex xs12 text-left>
                        <span
                          style="
                            font-weight: bold;
                            font-size: 16px;
                            text-transform: none;
                          "
                        >
                          Styl-Category
                        </span>
                      </v-flex>
                      <v-flex xs12 text-left>
                        <v-chip
                          v-for="(item, i) in styl"
                          :key="i"
                          class="pl-4 ma-2"
                          style="font-size: 14px"
                        >
                          {{ item.name }}
                        </v-chip>
                      </v-flex>
                    </v-layout>

                    <v-layout
                      wrap
                      justify-start
                      pt-2
                      v-if="product.tags && product.tags.length > 0"
                    >
                      <v-flex xs12 text-left>
                        <span
                          style="
                            font-weight: bold;
                            font-size: 16px;
                            color: #000000;
                          "
                        >
                          Tags
                        </span>
                      </v-flex>
                      <v-flex xs12 text-left>
                        <v-chip
                          v-for="(item, i) in product.tags"
                          :key="i"
                          class="pl-4 ma-2"
                          style="font-size: 14px"
                        >
                          {{ item }}
                        </v-chip>
                      </v-flex>
                    </v-layout>
                    <v-layout
                      wrap
                      justify-start
                      pt-2
                      v-if="
                        product.materialused && product.materialused.length > 0
                      "
                    >
                      <v-flex xs12 text-left pt-1 v-if="product.materialused">
                        <span
                          style="
                            font-weight: bold;
                            font-size: 16px;
                            color: #000000;
                          "
                        >
                          Material Used
                        </span>
                      </v-flex>
                      <v-flex xs12 text-left>
                        <v-chip
                          v-for="(item, i) in product.materialused"
                          :key="i"
                          class="pl-4 ma-2"
                          style="font-size: 14px"
                        >
                          {{ item }}
                        </v-chip>
                      </v-flex>
                    </v-layout>
                    <v-layout
                      wrap
                      justify-start
                      pt-2
                      v-if="product.seotags && product.seotags.length > 0"
                    >
                      <v-flex xs12 text-left pt-1 v-if="product.seotags">
                        <span
                          style="
                            font-weight: bold;
                            font-size: 16px;
                            color: #000000;
                          "
                        >
                          SEO Tags
                        </span>
                      </v-flex>
                      <v-flex xs12 text-left>
                        <v-chip
                          v-for="(item, i) in product.seotags"
                          :key="i"
                          class="pl-4 ma-2"
                          style="font-size: 14px"
                        >
                          {{ item }}
                        </v-chip>
                      </v-flex>
                    </v-layout>
                    <v-layout
                      wrap
                      justify-start
                      pt-2
                      v-if="product.keywords && product.keywords.length > 0"
                    >
                      <v-flex xs12 text-left pt-1 v-if="product.keywords">
                        <span
                          style="
                            font-weight: bold;
                            font-size: 16px;
                            color: #000000;
                          "
                        >
                          Keywords
                        </span>
                      </v-flex>
                      <v-flex xs12 text-left>
                        <v-chip
                          v-for="(item, i) in product.keywords"
                          :key="i"
                          class="pl-4 ma-2"
                          style="font-size: 14px"
                        >
                          {{ item }}
                        </v-chip>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap justify-start pt-2 v-if="product.title">
                      <v-flex xs12 text-left pt-1 v-if="product.title">
                        <span
                          style="
                            font-weight: bold;
                            font-size: 16px;
                            color: #000000;
                          "
                        >
                          Product Title
                        </span>
                      </v-flex>
                      <v-flex xs12 text-left>
                        {{ product.title }}
                      </v-flex>
                    </v-layout>
                    <v-layout wrap justify-start pt-2 v-if="product.fbpixel">
                      <v-flex xs12 text-left pt-1 v-if="product.fbpixel">
                        <span
                          style="
                            font-weight: bold;
                            font-size: 16px;
                            color: #000000;
                          "
                        >
                          Facebook Pixel
                        </span>
                      </v-flex>
                      <v-flex xs12 text-left>
                        {{ product.fbpixel }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-flex xs12 pt-4>
          <v-layout wrap justify-start>
            <v-flex xs6 sm6 md6 lg4 xl2 pl-5> </v-flex>
          </v-layout>
        </v-flex>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import InnerImageZoom from "vue-inner-image-zoom";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import ImageZoomer from "./imageZoomer";
export default {
  props: ["productid"],
  components: {
    ImageZoomer,
    "inner-image-zoom": InnerImageZoom,
  },
  data() {
    return {
      checkbox: true,
      tagsArray: [],
      msg: "",
      collectionid: "",
      stylCategoryArray: [],
      subcategoryArray: [],
      dialogdelete: false,
      showsnackbar: false,
      dialogadd: false,
      dialogapprove: false,
      productRating: 4,
      dialog: false,
      curImage: null,
      product: [],
      subcat: [],
      show: false,
      sizes: [],
      size: [],
      styl: [],
      stylCategory: [],
      productname: "",
      categoryArray: [],
      appLoading: false,
      ServerError: false,
      photos: [],
      name: "",
      productSize: {},
      selectedProduct: {},
      sizeChartDialog: false,
      sizeChart: null,
      commissionPercentage: null,
      deliveryCharge: null,
      imageUrl: null,
    };
  },
  mounted() {
    this.getData();
    this.getSubcategory();
    this.getStylCategory();
    this.getSizeChart();
  },
  methods: {
    showImage(item) {
      this.curImage = item;
    },
    copyImageUrl() {
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();
      try {
        var successful = document.execCommand("copy");
        var msg = successful
          ? " Image URL copied successfully"
          : "unsuccessful";
        this.msg = msg;
        this.showsnackbar = true;
      } catch (err) {
        this.msg = "Oops, unable to copy";
        this.showsnackbar = true;
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    changeSize(item) {
      this.productSize = item;
    },
    selectProduct(item) {
      this.selectedProduct = item;
    },
    getSizeChart() {
      // this.appLoading = true;
      axios({
        url: "/sizechart/get",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status) this.sizeChart = response.data.data.value;
          else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/product/info",
        params: {
          id: this.productid,
          topcollection: true,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.product = response.data.data;
            if (this.product.photos) this.photos = this.product.photos;
            this.sizes = this.product.size;
            this.styl = this.product.styl;
            this.categoryArray = this.product.category;
            this.subcat = response.data.data.subcategory;
            this.imageUrl = this.product.photos[0];
            this.appLoading = false;
          } else {
            this.appLoading = false;

            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((e) => {
          this.ServerError = true;
          console.log(e);
        });
    },
    getSubcategory() {
      axios({
        url: "/subcategory/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          if (response.data.status) {
            var temp = [];
            temp = response.data.data;
            // temp.reverse();
            // this.subcategoryArray = temp.slice(0, 3);
            for (var i = 0; i < temp.length; i++) {
              if (
                temp[i]._id == "5fbf36eba76eedbd31f5f24c" ||
                temp[i]._id == "60a4ba2631b3c87026f6123f" ||
                temp[i]._id == "6096ad5531b3c87026f6119f" ||
                temp[i]._id == "61b7285ded76da75cc2835e9" ||
                temp[i]._id == "61b72897ed76da75cc2835ea"
              ) {
                this.subcategoryArray.push(temp[i]);
              }
            }
            this.sizeArray = response.data.data.size;
            // this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            // this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem() {
      var data = {};
      data["productid"] = this.product._id;
      axios({
        url: "/home/topcollection/removebyproductid",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.dialogdelete = false;
            this.showsnackbar = true;
            this.getData();
            this.appLoading = false;
          } else {
            this.dialogdelete = false;

            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getStylCategory() {
      this.appLoading = true;
      axios({
        url: "/styl/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            // this.appLoading = false;
            this.stylCategoryArray = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            // this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    approveProduct() {
      axios({
        method: "POST",
        url: "/product/approve",
        data: {
          id: this.productid,
          approve: true,
          comissionPercentage: this.product.comissionPercentage,
          deliverycharge: this.product.deliverycharge,
          styl: this.product.styl,
          // ishighlated: this.checkbox,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.dialog = false;
            this.appLoading = false;
            this.getData();
            this.$router.go(-1);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.appLoading = false;
          }
        })
        .catch((e) => {
          this.ServerError = true;
          console.log(e);
        });
    },
    removeProduct() {
      var data = {};
      axios({
        method: "GET",
        params: {
          id: this.productid,
        },
        url: "/product/delete",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;

          if (response.data.status) {
            this.dialog = false;
            this.appLoading = false;
            this.$router.go(-1);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.appLoading = false;
          }
        })
        .catch((e) => {
          this.ServerError = true;
          console.log(e);
        });
    },
    edit(pid) {
      this.$router.push({
        name: "productEdit",
        params: { productid: pid },
      });
    },
    add() {
      var data = {};
      data["ishighlight"] = false;
      data["collectionid"] = this.collectionid;
      data["product"] = this.productid;

      axios({
        url: "/home/topcollection/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.dialogadd = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>
<style scoped>
.item_title {
  color: #7b7b7b;
  size: 14px;
}
.item_value {
  size: 16px;
}
.clip {
  clip-path: polygon(50% 0%, 0% 0%, 0% 100%, 0% 100%);
}
</style>
